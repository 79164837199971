import React from 'react'
import TestComponent from './TestComponent'

const App = () => {
  


return (
  <div className='container'>
    <div class="mt-4 p-5 bg-dark-subtle text-white rounded">
      <h1 className='display-1'>DEANMOORE.DEV</h1>
      <hr className='mb-4'/>
      <p className='lead text-success'>LINUX | PYTHON | DEVOPS | NETWORKS</p>
    </div>
    <div className='container d-flex justify-content-evenly mt-5'>
      <div className="card" style={{width: 18 + 'rem'}}>
        <div className="card-body">
          <h4 className="card-title">GitHub</h4>
          <p className="card-text">My public GitHub Profile</p>
          <a href="https://github.com/doghousedean" rel="noreferrer" target='_blank' className="btn btn-secondary stretched-link">GitHub</a>
        </div>
      </div>
      <div className="card" style={{width: 18 + 'rem'}}>
        <div className="card-body">
          <h5 className="card-title">LinkedIn</h5>
          <p className="card-text">If you're in to that sort of thing!</p>
          <a href="https://www.linkedin.com/in/dean-moore-nerd/" rel="noreferrer" target='_blank' className="btn btn-secondary stretched-link">LinkedIn</a>
        </div>
      </div>
      {/* <div className="card" style={{width: 18 + 'rem'}}>
        <div className="card-body">
          <h5 className="card-title">Card with stretched link</h5>
          <p className="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
          <a href="#" className="btn btn-secondary stretched-link">Go somewhere</a>
        </div>
      </div> */}
    </div>
    <hr />
    <TestComponent />
  </div>
)

}


export default App;